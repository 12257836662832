import { Avatar, InfiniteScroll, NavBar, Toast } from "antd-mobile";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer.tsx";
import { getTime } from "../utils/index.ts";
import { getPublisherShopList } from "../axios/index.js"
import defaultUser from "../assets/images/user-2.jpg";

export default function ShopManagement() {
    const navigate = useNavigate();
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [shopList, setShopList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        getPublisherShopList(query).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
                setHasMore(false);
            } else {
                pagenation.current.pageNum++;
                setShopList([...shopList, ...data.result]);
                setHasMore(data.result.length === 10);
            }
        }).finally(() => {
            pagenation.current.loading = false;;
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <NavBar back={null}>
                店铺查看
            </NavBar>
            <div style={{ flex: "1 1", background: "#f0f0f0", overflow: "auto" }}>
                {
                    shopList && shopList.map((shop) => (
                        <div 
                            key={shop._id}
                            style={{ display: "flex", gap: "10px", margin: "10px", padding: "10px", borderRadius: "10px", background: "white" }}
                            onClick={() => navigate(`/publisher/statistics/shop`, { state: { shop: shop._id } })}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Avatar src={defaultUser} style={{ "--size": "24px" }}/>
                            </div>
                            <div style={{ flex: "1 1" }}>
                                {shop.name}({ shop.ownerInfo.phone })<br />
                                <span style={{ color: "gray", fontSize: "13px" }}>{ getTime(shop.createTime) }</span>
                            </div>
                        </div>
                    ))
                }
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
            </div>
            <Footer />
        </div>
    )
}