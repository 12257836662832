import React, { useState, useMemo, useEffect } from "react";
import { Grid, Mask, Toast, NavBar } from "antd-mobile";
import Footer from "../components/Footer.tsx";
import { getOneDayStart, getOneDayEnd, getDay, getOneMonthStart, getOneMonthEnd, getMonth } from "../utils/index.ts";
import DatePicker from "../components/DatePicker.tsx";
import { DownOutline, UpOutline } from "antd-mobile-icons";
import { updateStatisticsTime } from "../store/shopSlice.ts";
import { useDispatch, useSelector } from "react-redux";
import { getPublisherStatistics } from "../axios/index.js";
import "./Statistics.css";
import { useLocation } from "react-router-dom";

export default function Statistics() {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state.user.user);
    const betRate = useMemo(() => user.betRate, [user]);
    const followBuyRate = useMemo(() => user.followBuyRate, [user]);
    const shop = useMemo(() => location.state?.shop || null, [location.state]);
    const statisticsTime = useSelector((state) => state.shop.statistics);
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [shopNum, setShopNum] = useState(0);
    const [userNum, setUserNum] = useState(0);
    const [ticketAmount, setTicketAmount] = useState(0);
    const [ticketNum, setTicketNum] = useState(0);
    const [followBuyAmount, setFollowBuyAmount] = useState(0);
    const [followBuyBonus, setFollowBuyBonus] = useState(0);
    const showTimeStr = useMemo(() => {
        if(statisticsTime.precision === "day") {
            return getDay(statisticsTime.startTime);
        }
        if(statisticsTime.precision === "month") {
            return getMonth(statisticsTime.startTime);
        }
        return ""
     }, [statisticsTime])

    useEffect(() => {
        getPublisherStatistics({
            startTime: statisticsTime.startTime,
            endTime: statisticsTime.endTime,
            shop,
        }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setShopNum(data.shopNum);
                setUserNum(data.userNum);
                setTicketAmount(data.betSum.ticketAmount);
                setTicketNum(data.betSum.ticketNum);
                setFollowBuyAmount(data.betSum.followBuyAmount);
                setFollowBuyBonus(data.betSum.followBuyBonus);
            }
        })
    }, [shop, statisticsTime])
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <NavBar
                back={null} 
                right={
                <div onClick={() => setDatePickerVisible(true)} style={{
                    background: "#f2f4f7",
                    color: "black",
                    padding: "5px",
                    borderRadius: "12px"
                }}>
                    {showTimeStr}
                    {
                        datePickerVisible ? <UpOutline style={{ marginLeft: "5px" }}/> : <DownOutline style={{ marginLeft: "5px" }}/>
                    }
                    
                </div>
            }>
                <div>数据统计</div>
            </NavBar>
            <div className="shop-statistics-container" style={{ flex: "1 1", overflowY: "auto" }}>
                <div className="statistics-box">                    
                    <Grid columns={2} gap={14}>
                        <Grid.Item span={1}>
                            <div className="half-top column space-between bg bg-1">
                                <div className="statistics-value">{ticketNum || 0} 单</div>
                                <div className="statistics-value">{ticketAmount.toFixed(2) || 0}</div>
                                <div className="statistics-name">总出票金额</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-top column space-between bg bg-2">
                                <div className="statistics-value">{(ticketAmount * betRate).toFixed(2) || 0}</div>
                                <div className="statistics-name">总出票佣金</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
                <div className="statistics-box" style={{marginTop: "12px"}}>
                    <Grid columns={2} style={{paddingTop: "12px"}} gap={14}>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-4">
                                <div className="statistics-value">{followBuyBonus.toFixed(2) || 0}</div>
                                <div className="statistics-name">总跟单获奖</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-5">
                                <div className="statistics-value">{(followBuyBonus * followBuyRate).toFixed(2) || 0}</div>
                                <div className="statistics-name">总跟单佣金</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
                <div className="statistics-box" style={{marginTop: "12px"}}>
                    <div style={{paddingBottom: "10px"}}>店铺统计：</div>
                    <Grid columns={2} gap={14}>
                        <Grid.Item span={1}>
                            <div className="half-top column flex-end bg bg-8" style={{ color: "black" }}>
                                <div className="statistics-value">{shopNum}</div>
                                <div className="statistics-name">店铺总数</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-top column flex-end bg bg-9" style={{ color: "black" }}>
                                <div className="statistics-value">{userNum}</div>
                                <div className="statistics-name">用户总数</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
            </div>
            <Footer />
            <Mask visible={datePickerVisible} onMaskClick={() => setDatePickerVisible(false)}>
                <div>
                    <DatePicker enablePrecision={true} defaultTime={statisticsTime.startTime} onChange={(time, precision) => {
                        setDatePickerVisible(false);
                        let _startTime, _endTime;
                        if(precision === "day") {
                            _startTime = getOneDayStart(time);
                            _endTime =getOneDayEnd(time);
                        }
                        if(precision === "month") {
                            _startTime =getOneMonthStart(time);
                            _endTime = getOneMonthEnd(time);
                        }
                        dispatch(updateStatisticsTime({
                            startTime: _startTime,
                            endTime: _endTime,
                            precision
                        }))
                    }} />
                </div>
            </Mask>
        </div>
    )
}