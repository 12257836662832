import { Mask, NavBar, Toast } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import Footer from "../components/Footer.tsx";
import { getPublisherBill } from "../axios/index.js";
import { getDaysInMonth, getMonth, getOneMonthEnd, getOneMonthStart, getMonthAndDay } from "../utils/index.ts";
import { DownOutline, UpOutline } from "antd-mobile-icons";
import DatePicker from "../components/DatePicker.tsx";
import { useSelector } from "react-redux";
import "./Bill.css";

export default function Bill() {
    const user = useSelector((state) => state.user.user);
    const betRate = useMemo(() => user.betRate, [user]);
    const followBuyRate = useMemo(() => user.followBuyRate, [user]);
    const now = useMemo(() => Date.now(), []);
    const [startTime, setStartTime] = useState(getOneMonthStart(now));
    const [endTime, setEndTime] = useState(getOneMonthEnd(now));
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [showTimeStr, setShowTimeStr] = useState(getMonth(now));
    const [billList, setBillList] = useState<any[]>([]);
    const [totalCommissions, setTotalCommissions] = useState('0');
    useEffect(() => {
        getPublisherBill({startTime, endTime}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                const { dailySum } = data;
                const dayMap = {};
                getDaysInMonth(startTime).forEach((val) => {
                    const _id = getMonthAndDay(val.getTime());
                    dayMap[_id] = { _id }
                });
                let _commission = 0;
                for(let daily of dailySum) {
                    dayMap[daily._id] = daily
                    _commission += daily.ticketAmount * betRate;
                    _commission += daily.followBuyBonus * followBuyRate;
                }
                setTotalCommissions(_commission.toFixed(2));
                setBillList(Object.values(dayMap))
            }
        })
    }, [endTime, startTime])
    return (
        <div style={{ display: "flex", flexDirection: 'column', height: '100%' }}>
            <NavBar back={null}
                right={
                    <div onClick={() => setDatePickerVisible(true)} style={{
                        background: "#f2f4f7",
                        color: "black",
                        padding: "5px",
                        borderRadius: "12px"
                    }}>
                        {showTimeStr}
                        {
                            datePickerVisible ? <UpOutline style={{ marginLeft: "5px" }}/> : <DownOutline style={{ marginLeft: "5px" }}/>
                        }
                        
                    </div>
                }>
                账单统计
            </NavBar>
            <div style={{ flex: '1', overflow: 'auto' }}>
                <div style={{ fontSize: '18px', margin: '10px 20px' }}>{showTimeStr}预估佣金：{totalCommissions}</div>
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    <table id="table">
                        <thead>
                            <tr>
                                <th>日期</th>
                                <th>出票金额</th>
                                <th>出票佣金</th>
                                <th>跟单获奖</th>
                                <th>跟单佣金</th>
                                <th>佣金总额</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                billList.map((bill) => {
                                    return (
                                        <tr>
                                            <td>{bill._id}</td>
                                            <td>{(bill.ticketAmount || 0).toFixed(2)}</td>
                                            <td>{((bill.ticketAmount || 0) * betRate).toFixed(2)}</td>
                                            <td>{(bill.followBuyBonus || 0).toFixed(2)}</td>
                                            <td>{((bill.followBuyBonus || 0) * followBuyRate).toFixed(2)}</td>
                                            <td>{((bill.ticketAmount || 0) * betRate + (bill.followBuyBonus || 0) * followBuyRate).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
            <Mask visible={datePickerVisible} onMaskClick={() => setDatePickerVisible(false)}>
                <div>
                    <DatePicker enablePrecision={false} defaultPrecision="month" defaultTime={now} onChange={(time, precision) => {
                        setDatePickerVisible(false);
                        if(precision === "month") {
                            setStartTime(getOneMonthStart(time));
                            setEndTime(getOneMonthEnd(time));
                            setShowTimeStr(getMonth(time));
                        }
                    }} />
                </div>
            </Mask>
        </div>
    )
}