import React, { useEffect }  from 'react';
import { SafeArea, Toast } from 'antd-mobile'
import './App.css';

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./modules/Login.tsx"
import PublisherStatistics from "./modules/Statistics.tsx";
import ShopManagement from './modules/ShopManagement.tsx';
import StatisticsShop from './modules/StatisticsShop.tsx';
import PersonalCenter from './modules/PersonalCenter.tsx';
import DrawMoney from './modules/DrawMoney.tsx';
import DrawHistory from './modules/DrawHistory.tsx';
import ShopRegister from './modules/ShopRegister.tsx';
import Bill from './modules/Bill.tsx';
import PersonSetting from './modules/PersonSetting.tsx';
import Password from './modules/Password.tsx';
import PayPassword from './modules/PayPassword.tsx';

import { useDispatch } from 'react-redux';
import { getAccount } from './axios';
import { set } from './store/userSlice.js';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    getAccount({}).then(({data}) => {
      if(data.status === 'error') {
        Toast.show(data.errorMessage);
      } else {
        dispatch(set(data.result))
      }
    })
  }, [])
  return (
    <Router>
      <div className="App">
        <SafeArea position='top' />
        <div className='body'>
          <Routes>
            <Route path="/" Component={PublisherStatistics}></Route>
            <Route path="/login" Component={Login}></Route>
            <Route path="/publisher/statistics" Component={PublisherStatistics}></Route>
            <Route path="/publisher/statistics/shop" Component={StatisticsShop}></Route>
            <Route path="/publisher/person" Component={PersonalCenter}></Route>
            <Route path="/publisher/shop" Component={ShopManagement}></Route>
            <Route path="/publisher/draw" Component={DrawMoney}></Route>
            <Route path="/publisher/draw/history" Component={DrawHistory}></Route>
            <Route path="/publisher/share" Component={ShopRegister}></Route>
            <Route path="/publisher/bill" Component={Bill}></Route>
            <Route path="/publisher/person/setting" Component={PersonSetting}></Route>
            <Route path="/publisher/password" Component={Password}></Route>
            <Route path="/publisher/paypassword" Component={PayPassword}></Route>
            
          </Routes>
        </div>
        <SafeArea position='bottom' />
      </div>
    </Router>
  );
}

export default App;
