import { get, post } from './base';

export function login(data) {
    return post("/publisher/login", data)
}
export function getAccount(params) {
    return get("/publisher/account", params);
}

export function getPublisherStatistics(params) {
    return get("/publisher/statistics", params);
}

export function getPublisherBill(params) {
    return get("/publisher/bill", params);
}

export function getPublisherShopList(params) {
    return get("/publisher/shop/list", params);
}

export function getPhoneCaptcha(params) {
    return get("/publisher/phone/captcha", params)
}

export function postPublisherPayPasswordChange(data) {
    return post("/publisher/paypassword/change", data);
}

export function postPublisherPasswordChange(data) {
    return post("/publisher/password/change", data);
}

export function postPublisherDraw(body) {
    return post('/publisher/draw', body)
}

export function getPublisherDrawList(body) {
    return get('/publisher/draw/list', body)
}
