import { cnNameMap } from "../constant/cnNameMap.ts";

/**
 * [[1], [2,3], [4]] => [[1,2,4], [1,3,4]]
*/
export function splitArray(arr: any[]) {
  const result: any[] = [];
  const combinations: any[] = [];

  function backtrack(index: number, combination: any[]) {
    if (index === arr.length) {
      result.push(combination.slice());
      return;
    }

    const currentRow = arr[index];
    for (let i = 0; i < currentRow.length; i++) {
      combination.push(currentRow[i]);
      backtrack(index + 1, combination);
      combination.pop();
    }
  }

  backtrack(0, combinations);
  return result;
};
/**
 * [1,3,5] => [[1,3],[1,5],[3,5]]
*/
export function splitArrayByN(arr: any[], N: number) {
  const result: any[] = [];

  function backtrack(start: number, combination: any[]) {
    if (combination.length === N) {
      result.push(combination.slice());
      return;
    }

    for (let i = start; i < arr.length; i++) {
      combination.push(arr[i]);
      backtrack(i + 1, combination);
      combination.pop();
    }
  }

  backtrack(0, []);
  return result;
}

export function getTimeInDay(time: string | number) {
  if(!time) return ""
  const date = new Date(time);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function getTime(time: string | number) {
  if(!time) return ""
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getTimeInYear(time: string | number) {
  if(!time) return ""
  const date = new Date(time);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${month}-${day} ${hours}:${minutes}`;
}

export function getTimeInMonth(time: string) {
  if(!time) return ""
  const date = new Date(time);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${month}-${day} ${hours}:${minutes}`;
}

export function getMonthAndDay(time: string | number) {
  if(!time) return ""
  const date = new Date(time);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}-${day}`;
}

export function getDay(time: string | number) {
  if(!time) return ""
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getMonth(time: string | number) {
  if(!time) return ""
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
}

export function getOneDayStart(time: string | number) {
  if(!time) return 0
  const date = new Date(time);
  date.setHours(0, 0, 1);
  return date.getTime();
}

export function getOneDayEnd(time: string | number) {
  if(!time) return 0
  const date = new Date(time);
  date.setHours(23, 23, 59);
  return date.getTime();
}

export function getOneMonthStart(time: string | number) {
  if(!time) return 0
  const now = new Date(time);
  var nowMonth = now.getMonth(); //当前月 
  var nowYear = now.getFullYear(); //当前年 
  var monthStartDate = new Date(nowYear, nowMonth, 1); 
  monthStartDate.setHours(0, 0, 1);
  return monthStartDate.getTime();
}

export function getOneMonthEnd(time: string | number) {
  if(!time) return 0
  const now = new Date(time);
  var nowMonth = now.getMonth(); //当前月 
  var nowYear = now.getFullYear(); //当前年 
  if (nowMonth === 11) nowYear++;
  var monthEndDate = new Date(nowYear, (nowMonth +1) % 12, 1); 
  monthEndDate.setHours(0, 0, 0);
  return monthEndDate.getTime()-1;
}

export function getDaysInMonth(time: string | number) {
  const year = new Date(time).getFullYear();
  const month = new Date(time).getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const daysArray: Date[] = [];

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(new Date(year, month, day));
  }

  return daysArray;
}

export function getDayFromCreateDate(time: string | number) {
  const now = Date.now();
  const day = Math.ceil((now - Number(time)) / (24 * 60 * 60 * 1000));
  return isNaN(day) ? 0 : day;
}

export function generateRandomNumber() {
  const randomNumber = Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1) + 1000000000000000);
  return String(randomNumber);
}

export function getPickThreeTerm() {
  const currentYear = new Date().getFullYear().toString();
    const _currentYear = currentYear.slice(-2);
    // 今天减今年的第一天（xxxx年01月01日）
    const hasTimestamp = new Date().getTime() - new Date(currentYear).getTime();
    // 86400000 = 24 * 60 * 60 * 1000
    const hasDays = Math.ceil(hasTimestamp / 86400000) + 1;
    const term = `${_currentYear}${hasDays}`;
    return term
}

function shuffleArray(array: number[]) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

function getRandomNFromArray(array: number[], N: number) {
  if (N >= array.length) {
      return array.slice(); // Return a copy of the whole array
  }

  const shuffledArray = array.slice(); // Create a copy of the array
  shuffleArray(shuffledArray); // Shuffle the copy

  return shuffledArray.slice(0, N); // Return the first N elements
}

export function getRandom(start: number, end: number, num: number, filter: number[] = []): Set<number> {
  const arr = [];
  for(let i = start; i <= end; i++) {
      if(!filter.includes(i)) {
          arr.push(i);
      }
  }
  const result = getRandomNFromArray(arr, num);
  return new Set(result)
}

const option2Num: any = {
  "1^1": 1,
  "2^1": 2,
  "3^1": 3,
  "4^1": 4,
  "5^1": 5,
  "6^1": 6,
  "7^1": 7,
  "8^1": 8,
}

export function options2Num(options: string) {
  if(options && typeof options === "string") {
    return options.split("_").map((option) => option2Num[option])
  }
  return "";
}

export function options2Str(options: string) {
  if(options && typeof options === "string") {
    return options.split("_").map((option: string) => cnNameMap[option]).join(",")
  }
  return "";
}

export function deepCopy<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Set) {
    const copySet = new Set();
    obj.forEach((item) => {
      copySet.add(deepCopy(item));
    });
    return copySet as unknown as T;
  }

  if (Array.isArray(obj)) {
    const copyArr: any[] = [];
    obj.forEach((item) => {
      copyArr.push(deepCopy(item));
    });
    return copyArr as unknown as T;
  }

  const copyObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    copyObj[key] = deepCopy(obj[key]);
  });

  return copyObj as T;
}