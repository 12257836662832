import React, { useState } from 'react';
import { Toast } from 'antd-mobile';
import { useDispatch } from 'react-redux';
import { set } from "../store/userSlice.js";
import { login } from '../axios/index.js';
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleLogin = () => {
    login({
        phone:phoneNumber, password
    }).then(({data}) => {
      if(data.status === 'error') {
        Toast.show(data.errorMessage);
      } else {
        dispatch(set(data));
        navigate("/");
      }
    }).catch((e) => {
        Toast.show("登录失败，请检查手机号和密码");
    })
  };

  return (
      <div className="aui-flexView">
          <div className="aui-navBar aui-navBar-fixed" style={{ height: "300px", background: "linear-gradient(#ff4800, #ffffff)" }}>
              <div className="aui-center">
                  <span className="aui-center-title">登录</span>
              </div>
          </div>
          <div style={{ backgroundColor: "#fff", borderRadius: "12px", boxShadow: "#ff48003f 0 3px 6px", padding: "30px 20px", margin: "0 15px", marginTop: "-30px", position: "relative", zIndex: 3 }}>
            <input
              type="text" 
              className="login-input" 
              name="username" 
              style={{ background: "url(images/login-user.jpg) no-repeat 9px center", backgroundSize: "22px" }} 
              placeholder="手机号" 
              onChange={(e) => handlePhoneNumberChange(e.target.value)}/>
            <input 
              type="password" 
              className="login-input" 
              name="password" 
              style={{ background: "url(images/login-lock.jpg) no-repeat 9px center", backgroundSize: "22px", letterSpacing: "9px" }} 
              placeholder="密码"
              onChange={(e) => handlePasswordChange(e.target.value)}/>
            <div style={{ margin: "6px 9px" }}>
                <input type="checkbox" className="check-orange" style={{ verticalAlign: "middle" }}/>
                <span style={{ color: "#9f9f9f" }}>记住密码</span>
                <div style={{ textAlign: "right", float: "right" }}><a href="javascript:;" style={{ color: "#9f9f9f" }}>忘记密码？</a></div>
            </div>
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <button className="btn-orange btn-main" onClick={handleLogin}>立即登录</button>
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "20px", width: "100%", textAlign: "center" }}><a href="javascript:;" className="font-orange">在线客服</a></div>
      </div>
  );
};

export default Login;