import { NavBar, List } from "antd-mobile";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PersonSetting() {
    const navigate = useNavigate();
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                店铺信息
            </NavBar>
            <List>
                <List.Item clickable onClick={() => navigate("/publisher/password")}>
                    登录密码
                </List.Item>
                <List.Item clickable onClick={() => navigate("/publisher/paypassword")}>
                    支付密码
                </List.Item>
            </List>
        </div>
    )
}