import React, { useMemo, useState } from "react";
import { DatePickerView } from "antd-mobile";
import { Precision } from "antd-mobile/es/components/date-picker/date-picker-utils";

export default function DatePicker(props: {
    onChange: (time: number, precision: Precision) => void;
    defaultTime?: number,
    enablePrecision?: boolean,
    defaultPrecision?: Precision,
}) {
    const { onChange, defaultTime, enablePrecision = false, defaultPrecision = 'day' } = props;
    const now = useMemo(() => (new Date().getTime()), []);
    const [precision, setPrecision] = useState<Precision>(defaultPrecision);
    const [time, setTime] = useState(defaultTime || now);

    return (
        <div style={{
            width: "100%",
            backgroundColor: "white",
            position: "fixed",
            bottom: 0,
        }}>
            <div style={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "10px"
            }}>
                {
                    enablePrecision && (
                        <div onClick={() => {
                            if(precision === "day") {
                                setPrecision("month");
                            } else {
                                setPrecision("day");
                            }
                        }}>{ precision === "day" ? "按月选择" : "按日选择" }</div>
                    ) 
                }
                <div onClick={() => onChange(time, precision)}>确认</div>
            </div>
            <DatePickerView
                precision={precision}
                defaultValue={new Date(time)}
                onChange={(val) => {
                    setTime(val.getTime());
                }}
                filter={{
                    day: (val, { date })=>{
                        if(date.getTime() < new Date().getTime()) {
                            return true
                        } else {
                        return false
                        }
                    },
                    month: (val, { date })=>{
                        if(date.getTime() < new Date().getTime()) {
                            return true
                        } else {
                        return false
                        }
                    },
                    year: (val, { date })=>{
                        if(date.getTime() < new Date().getTime()) {
                            return true
                        } else {
                        return false
                        }
                    },
                }}
            />
        </div>
    )
}