import React from "react";
import { TabBar } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";

import FooterIcon1Png from "../assets/shop/footer-icon1.png";
import FooterIcon2Png from "../assets/shop/footer-icon2.png";
import FooterIcon3Png from "../assets/shop/footer-icon3.png";
import FooterIcon4Png from "../assets/shop/footer-icon4.png";

export default function Footer() {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location

    const setRouteActive = (value: string) => {
        navigate(value)
    }

    const tabs = [
        {
          key: "/publisher/statistics",
          title: "数据统计",
          icon: <img src={FooterIcon1Png} width={30}/>
        },
        {
          key: '/publisher/shop',
          title: '店铺统计',
          icon: <img src={FooterIcon2Png} width={30}/>
        },
        {
          key: '/publisher/bill',
          title: '账单统计',
          icon: <img src={FooterIcon3Png} width={30}/>
        },
        {
          key: '/publisher/person',
          title: '个人中心',
          icon: <img src={FooterIcon4Png} width={30}/>
        },
      ]

    return (
      <div style={{ backgroundColor: "white" }}>
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
      </div>
    )
}