import React from "react";
import { useNavigate } from "react-router-dom";
import "./PersonalCenter.css"
import { useDispatch, useSelector } from "react-redux";
import { clear } from "../store/shopSlice.ts";

import { Button, Image, List, Space } from "antd-mobile";
import { CheckShieldOutline } from "antd-mobile-icons";
import DefaultUser from "../assets/shop/user-avatar.png";

import Footer from "../components/Footer.tsx";

export default function PersonalCenter() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const handleLogout = async() => {
        dispatch(clear());
        localStorage.setItem('token', '');
        navigate("/login");
    }
    return (
        <div className="shop-personal-center" style={{display: "flex", height: "100%", flexDirection: "column", backgroundColor: "var(--whole-page-background)"}}>
            <div style={{ flex: "1 1", overflowY: "scroll" }}>
                <div className="personalInfoContainer">
                    <div style={{ display: "flex", margin: "15px 0", zIndex: 1 }}>
                        <Space direction="horizontal" align="start">
                            <div className="avatar">
                                <Image src={DefaultUser} width={50} height={50} fit='fill' style={{ borderRadius: "50%" }}/>
                            </div>
                        </Space>
                        <div className="row space-between flex-1">
                            <div className="name column space-between flex-1">
                                <div>{user.phone}(推广码：{user.agentKey})</div>
                                <div className="personal-checked"><CheckShieldOutline />{user.level}级推广</div>
                            </div>
                        </div>
                    </div>
                    <div className="shop-balance">
                        <div className="column bg-2 balance-block" onClick={() => { navigate("/publisher/draw") }}>
                            <div>
                                <div className="personal-highlight">{user.balance?.toFixed(2)}</div>
                                <div className="person-ps">佣金余额</div>
                            </div>
                        <div style={{  }}><Button color="primary" size="small">提现</Button></div>
                        </div>
                    </div>
                    <div className="person-container-bg"></div>
                </div>
                <div style={{ borderRadius: '25px', overflow: "hidden" }}>
                    <List className="manage-tools-list" style={{padding: "0px"}}>
                        <List.Item prefix={<div className="prefix-11"></div>} onClick={() => { navigate("/publisher/share") }}>
                            邀请店主
                        </List.Item>
                    </List>
                    <List className="manage-tools-list" style={{padding: "0px"}}>
                        <List.Item prefix={<div className="prefix-11"></div>} onClick={() => { navigate("/publisher/person/setting") }}>
                            设置中心
                        </List.Item>
                    </List>
                    <div style={{ height: "50px", textAlign: "center", lineHeight: "45px" }} onClick={() => handleLogout()}>
                        登出账户
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}